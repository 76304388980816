export enum Products {
  AC = "air_con",
  BatteryOnly = "battery_only",
  Boiler = "boiler",
  BoilerRental = "boiler-rental",
  EV = "ev_charger",
  Heatlink = "heatlink",
  HeatPump = "heat_pump",
  HomeCover = "homecover",
  HomeServe = "homeserve",
  ProductRecall = "product_recall",
  Repair = "repair",
  Services = "services",
  SmartHome = "smart-home",
  Solar = "solar",
  ReportIssue = "report-issue",
}

export const ProductTypeToCategory = {
  [Products.Repair]: "boiler_repair",
  [Products.Boiler]: "boiler_install",
  [Products.BoilerRental]: "boiler_rental",
  [Products.HomeCover]: "homecover",
  [Products.AC]: "air_con_install",
  [Products.EV]: "ev_charger_install",
  [Products.Solar]: "solar_install",
  [Products.HeatPump]: "heat_pump_install",
  [Products.BatteryOnly]: "battery_only_install",
};

export type PackageTypes =
  | "air_con"
  | "battery"
  | "battery_only"
  | "boiler"
  | "control"
  | "flue"
  | "general"
  | "heat_pump"
  | "inverter"
  | "other"
  | "panel"
  | "radiator"
  | "solar_quality"
  | "trv"
  | "water_cylinder";
