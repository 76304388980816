import type { NextPageContext } from "next";
import NextErrorComponent from "next/error";

import Bugsnag from "../lib/bugsnag";

const ErrorPage = ({ statusCode, hasGetInitialPropsRun, err }) => {
  if (!hasGetInitialPropsRun && err) {
    // getInitialProps is not called in case of
    // https://github.com/vercel/next.js/issues/8592. As a workaround, we pass
    // err via _app.js so it can be captured
    Bugsnag.notify(err, (event) => {
      event.severity = "error";
      event.unhandled = true;
    });
  }

  return <NextErrorComponent statusCode={statusCode} />;
};

ErrorPage.getInitialProps = async ({ req, res, err, asPath }) => {
  const errorInitialProps = await NextErrorComponent.getInitialProps({
    res,
    err,
  } as NextPageContext);

  // Workaround for https://github.com/vercel/next.js/issues/8592, mark when
  // getInitialProps has run
  const errorProps = { ...errorInitialProps, hasGetInitialPropsRun: true };

  // Running on the server, the response object (`res`) is available.
  //
  // Next.js will pass an err on the server if a page's data fetching methods
  // threw or returned a Promise that rejected
  //
  // Running on the client (browser), Next.js will provide an err if:
  //
  //  - a page's `getInitialProps` threw or returned a Promise that rejected
  //  - an exception was thrown somewhere in the React lifecycle (render,
  //    componentDidMount, etc) that was caught by Next.js's React Error
  //    Boundary. Read more about what types of exceptions are caught by Error
  //    Boundaries: https://reactjs.org/docs/error-boundaries.html

  if (err) {
    Bugsnag.notify(err, (event) => {
      event.severity = "error";
      event.unhandled = true;
      event.request = req;
    });

    /**
     * NewRelic error reporting
     */
    if (typeof window === "undefined") {
      const newrelic = await import("newrelic");
      newrelic.noticeError(err);
    } else {
      // @ts-expect-error window is not typed but newrelic is inject on _document.tsx
      window.newrelic.noticeError(err);
    }

    return errorProps;
  }

  // If this point is reached, getInitialProps was called without any
  // information about what the error might be. This is unexpected and may
  // indicate a bug introduced in Next.js, so record it in Bugsnag
  Bugsnag.notify(new Error(`_error.js getInitialProps missing data at path: ${asPath}`), (event) => {
    event.severity = "error";
    event.unhandled = true;
    event.request = req;
  });

  return errorInitialProps;
};

export default ErrorPage;
