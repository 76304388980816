import type { ReactNode } from "react";
import { useEffect, useState } from "react";
import type { primitive } from "@flagship.io/react-sdk";
import { DEVICE_TYPE, FlagshipProvider, LogLevel } from "@flagship.io/react-sdk";
import { parseCookies } from "nookies";

import onVisitorExposedHelper from "@Helpers/onVisitorExposedHelper";

import { FlagshipScreenerContext } from "./FlagshipContext";

type Props = {
  children: ReactNode;
  apiKey: string;
  envId: string;
  enableClientCache: boolean;
  setHasFlagshipLoaded: (_: boolean) => void;
};

const FlagshipWrapper = ({ children, apiKey, envId, enableClientCache, setHasFlagshipLoaded }: Props) => {
  const [fsUserScreenerContext, setFsUserScreenerContext] = useState<Record<string, primitive>>({});
  const cookies = parseCookies();
  const [segmentId, setSegmentId] = useState(cookies["ajs_anonymous_id"]);
  const [adminToken, setAdminToken] = useState(cookies["boxt_admin_token"]);
  const [deviceType, setDeviceType] = useState<"mobile" | "desktop">("desktop");

  const deviceTypeCheck = () => {
    if ("maxTouchPoints" in navigator) {
      if (navigator.maxTouchPoints > 0) {
        return setDeviceType("mobile");
      }
    } else {
      const mQ = matchMedia?.("(pointer:coarse)");
      if (mQ?.media === "(pointer:coarse)") {
        return setDeviceType("mobile");
      }
    }
  };

  useEffect(() => {
    if (window !== undefined) {
      const cookies = parseCookies();

      const id = cookies["ajs_anonymous_id"];
      setSegmentId(id);

      const adminToken = cookies["boxt_admin_token"];
      setAdminToken(adminToken);

      deviceTypeCheck();
    }
  }, []);

  return (
    <FlagshipScreenerContext.Provider value={{ fsUserScreenerContext, setFsUserScreenerContext }}>
      <FlagshipProvider
        apiKey={apiKey}
        enableClientCache={enableClientCache}
        logLevel={LogLevel.ERROR}
        envId={envId}
        visitorData={{
          id: segmentId,
          isAuthenticated: Boolean(segmentId),
          context: { [DEVICE_TYPE]: deviceType, hasAdminToken: Boolean(adminToken), ...fsUserScreenerContext },
        }}
        onUpdate={() => setHasFlagshipLoaded(true)}
        onVisitorExposed={({ fromFlag }) => {
          onVisitorExposedHelper({
            key: fromFlag.key,
            metaData: fromFlag.metadata,
            segmentAnalytics: window?.analytics,
          });
        }}
      >
        {children}
      </FlagshipProvider>
    </FlagshipScreenerContext.Provider>
  );
};

export default FlagshipWrapper;
