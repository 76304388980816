import type { Dispatch, SetStateAction } from "react";
import { createContext } from "react";
import type { primitive } from "@flagship.io/js-sdk";

type userContext = Record<string, primitive>;

interface ContextProps {
  fsUserScreenerContext?: userContext;
  setFsUserScreenerContext?: Dispatch<SetStateAction<userContext>>;
}

export const FlagshipScreenerContext = createContext<ContextProps>({});
