const mockRentalInitialStateValues = {
  selectedDate: "",
  installationAddress: {
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    city: "",
    county: "",
    postcode: "",
    note: "",
  },
  addressHistory: [],
  financialInfo: {
    employmentStatus: "",
    takeHomePay: "",
    householdIncome: "",
    mortgagePayments: "",
    otherExpenses: "",
    numberOfAdults: "",
    numberOfChildren: "",
    financialSituationChanged: "",
  },
  title: "",
  firstName: "",
  surname: "",
  email: "",
  telephone: "",
  dateOfBirth: "",
  acceptTerms: false,
  acceptMailing: false,
};

export default mockRentalInitialStateValues;
