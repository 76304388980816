"use-client";

import { createGlobalStyle } from "styled-components";
import { colors } from "@boxt/design-system";

const GlobalStyles = createGlobalStyle`
  html {
      &.lenis {
          height: auto;

          &.lenis-smooth {
              scroll-behavior: auto;
          }
      }

      body {
          background-color: ${colors.white};
          min-height: 100vh;
          font-family: Sofia Pro, Helvetica, Arial, sans-serif;
          overflow-x: hidden;
      }
  }
`;

export default GlobalStyles;
