import type {
  AnyActorRef,
  NonReducibleUnknown,
  ParameterizedObject,
  ProvidedActor,
  StateMachine,
  StateValue,
} from "xstate";
import type {
  EventObject,
  MachineContext,
  MetaObject,
  SnapshotFrom,
  StateSchema,
} from "xstate/dist/declarations/src/types";

import { Products } from "@Collections/products";
import type { Answer, Event, Template } from "@StateMachines/Screener";
import type { AnswerQuestionType, BackType, ScreenerContext } from "@StateMachines/Screener/types";
import type { ScreeningQuery } from "@Components/Checkout";

export type Meta = {
  next: string;
  prev?: string;
  isFirstQuestion?: boolean;
  hasHelpText?: boolean;
  event?: Event;
  options?: Record<string, string>;
  context?: string;
  iconName?: string;
};

export type MetaOptions = {
  [key: string]: string | Record<string, string>;
};

export type SchemaAnswer = {
  meta: {
    hasInfo?: boolean;
    event?: Event;
    next?: string;
    icon?: string;
    isFullWidthIcon?: boolean;
    options?: MetaOptions;
    createEvent?: boolean;
    localeKey?: string;
    price?: number;
  };
  value: Answer;
};

export type SchemaQuestionType = {
  initial?: string;
  meta: Meta;
  answers?: SchemaAnswer[];
  states?: {
    [key: string]: {
      meta: Meta;
      answers?: SchemaAnswer[];
    };
  };
};

export type SchemaQuestion = Record<string, SchemaQuestionType>;

export type Schema = {
  meta: {
    initialQuestion: string;
    initialProgress?: string;
    editableOnComplete?: string[];
    id: string;
  };
  questions: {
    [key: string]: {
      meta: {
        isLastQuestion?: boolean;
        isFirstQuestion?: boolean;
        prev?: string;
        template?: Template;
        next: string;
        event?: Event;
        hasHelpText?: boolean;
        paths?: string[];
        hasAdditionalInfo?: boolean;
      };
      answers?: SchemaAnswer[];
      initial?: string;
      states?: {
        [key: string]: {
          meta: {
            template?: Template;
            next: string;
            prev: string;
          };
          answers?: SchemaAnswer[];
        };
      };
    };
  };
};

export type BuildStatesType = {
  [key: string]: {
    meta?: {
      template?: Template;
      next: string;
      prev: string;
      on: Partial<Record<Event, AnswerQuestionType | AnswerQuestionType[]>>;
      answers?: SchemaAnswer[];
      progress?: number;
      paths?: string[];
    };
    states?: Record<string, string>;
    type?: string;
    on?: Partial<Record<Event, AnswerQuestionType | AnswerQuestionType[] | BackType>>;
  };
};

export type BuildStateType = {
  meta: {
    isLastQuestion?: boolean;
    isFirstQuestion?: boolean;
    prev?: string;
    next: string;
    hasHelpText?: boolean;
    answers?: SchemaAnswer[];
  };
  initial?: string;
  on: Partial<Record<Event, AnswerQuestionType | AnswerQuestionType[]>>;
  states?: BuildStatesType;
};

export type TStateMachine<C extends MachineContext, E extends EventObject> = StateMachine<
  C,
  E,
  Record<string, AnyActorRef>,
  ProvidedActor,
  ParameterizedObject,
  ParameterizedObject,
  string,
  StateValue,
  string,
  unknown,
  NonReducibleUnknown,
  E,
  MetaObject,
  StateSchema
>;

export type ScreenerOptionsType = {
  id: string;
  initial: string;
  context: ScreenerContext;
  states: any;
};

export type ScreenerState<C extends MachineContext, E extends EventObject> = SnapshotFrom<TStateMachine<C, E>>;

// screener variant to retrieve schema
export enum ScreenerVariant {
  BoilerRepair = "repair/boiler",
  Heatlink = "repair/heatlink",
  HomeCover = "homecover/boiler",
  HomeServe = "homeserve/boiler",
  BoilerInstallation = "installation/boiler",
  BoilerInstallationExperiment = "installation/boiler-experiment",
  BoilerSubscription = "subscription/boiler",
  CalloutInstallation = "callout/installation",
  CalloutSubscription = "callout/subscription",
  Solar = "installation/solar",
  EVCharger = "installation/ev-charger",
  HeatPump = "installation/heat-pump",
  AirCondition = "installation/air-conditioning",
  BatteryOnly = "installation/battery-only",
}

export enum ProductType {
  BoilerRepair = "boiler_repair",
  Heatlink = "boiler_repair",
  HomeCover = "homecover",
  BoilerSubscription = "boiler",
  BoilerInstallation = "boiler",
  SolarInstallation = "solar",
  EV = "ev_charger",
  HeatPump = "heat_pump",
  AC = "air_con",
  BatteryOnly = "battery_only",
}

export const variantMapper: Partial<Record<Products, string>> = {
  [Products.AC]: ScreenerVariant.AirCondition,
  [Products.BatteryOnly]: ScreenerVariant.BatteryOnly,
  [Products.Boiler]: ScreenerVariant.BoilerInstallation,
  [Products.HeatPump]: ScreenerVariant.HeatPump,
};

export enum InstallationProductTypes {
  BatteryOnly = "battery_only",
  Boiler = "boiler",
  EvCharger = "ev_charger",
  AirCondition = "air_con",
}

export type PageSteps<T> = {
  setStep: (step: T) => void;
  setStepOnBack: (_: () => void) => void;
  setShouldRouteChange: (_: ((to: string) => void) | null) => boolean;
};

export enum ScreenerAppendedParams {
  OpenModal = "openModal",
  OpenSideDrawer = "openSideDrawer",
}

export type HomeType = "detached" | "semi_detached" | "terrace" | "flat" | "bungalow";

export type CostOfUnitOfElectricity = {
  unitPrice: number | string;
  dayUnitPrice: number | string;
  nightUnitPrice: number | string;
  differentRateAtNight: YesOrNo;
};

export type ScreenerData = {
  id: string;
  postcode: string;
  productType: ProductType;
  screeningHistory: {
    id: number;
    screeningId: string;
    content: ScreeningContent;
    createdAt: string;
    updatedAt: string;
  };
  screeningQuery: ScreeningQuery;
};

export type ScreeningContent = {
  postcode: string;
  homeType?: HomeType;
  roofType?: "pitched" | "flat";
  buyFromBoxt?: YesOrNo;
  homeLocation?: {
    lat: number;
    lng: number;
  };
  shadeOnRoof?: YesOrNo;
  propertyOwnership?: string;
  annualElectricityUsage?: number;
  costOfUnitOfElectricity?: CostOfUnitOfElectricity;
};

type YesOrNo = "yes" | "no";
